import axios from 'axios';
import GalaworkApiError from '~/errors/GalaworkApiError';
import { getGalaworkApiToken, isLocal } from './helpers';

// @todo: integrate the usage of window variables in the Reducer
let galaworkApiUrl: string = (window as any).reactData.galaworkApiUrl ?? '';

if (galaworkApiUrl === '') {
  galaworkApiUrl = REACT_GALAWORK_API_URL;
}

const ApiService = axios.create({
  baseURL: galaworkApiUrl,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'de,de-DE',
  },
  withCredentials: true,
});

ApiService.interceptors.request.use(config => {
  if (isLocal() && !Object.prototype.hasOwnProperty.call(config.headers, 'Authentication')) {
    // Add missing authentication token, if known
    const jwtToken = getGalaworkApiToken();

    if (null !== jwtToken) {
      config.headers.set('Authorization', `Bearer ${jwtToken}`);
    }
  }

  return config;
});

ApiService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // The request was made, but the server responded with an error
      throw new GalaworkApiError(`API Error: ${error.response.status} - ${error.response.statusText}`, error);
    } else if (error.request) {
      // The request was made, but no response was received
      throw new GalaworkApiError('API Error: No response received from the server', error);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new GalaworkApiError(`API Error: ${error.message}`, error);
    }
  }
);

export default ApiService;
