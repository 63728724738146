import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations_de from './locales/de/translations-de';
import translations_en from './locales/en/translations-en';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translations_en },
    de: { translation: translations_de },
  },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
