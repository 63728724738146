import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  isGalaworkLight: boolean;
  isUser: boolean;
}

const globalUserData: { isGalaworkLight: boolean; isUser: boolean } = (window as any).reactData;

const initialState: UserState = {
  isGalaworkLight: globalUserData.isGalaworkLight,
  isUser: globalUserData.isUser,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: state => {
      state.isUser = true;
    },
    logout: state => {
      state.isUser = false;
    },
    toggleGalaworkLight: state => {
      state.isGalaworkLight = !state.isGalaworkLight;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
