const en = {
  globals: {
    buttons: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Save',
      cancel: 'Cancel',
    },
    titles: {
      currentWindow: 'Current Window',
      newWindow: 'New Window',
    },
    words: {
      total: 'Total',
      standard: 'Standard',
      detailed: 'Detailed',
      last7Days: 'Last 7 Days',
      last14Days: 'Last 14 Days',
      last30Days: 'Last 30 Days',
      last90Days: 'Last 90 Days',
      actualMonth: 'Actual Month',
      lastMonth: 'Last Month',
      last3Months: 'Last 3 Months',
      day: 'Day',
      days: 'Days',
      readMore: 'Read more',
    },
    labels: {
      navigationTitle: 'Navigation Title',
      icon: 'Icon',
      target: 'Target',
      url: 'URL',
    },
    placeholders: {
      navigationTitle: 'Type navigation title',
      icon: 'Search icon',
      target: 'Choose target',
      url: 'Type URL',
    },
    icons: {
      titles: {
        gear: 'Gear',
        hammer: 'Hammer',
        wrench: 'Wrench',
        hardHat: 'Hard Hat',
        excavator: 'Excavator',
        fireExtinguisher: 'Fire Extinguisher',
        hospital: 'Hospital',
        ambulance: 'Ambulance',
        heartbeat: 'Heartbeat',
        medkit: 'Medkit',
        pills: 'Pills',
        syringe: 'Syringe',
        userDoctor: 'User Doctor',
        tooth: 'Tooth',
        graduationCap: 'Graduation Cap',
        book: 'Book',
        chalkboardTeacher: 'Chalkboard Teacher',
        laptop: 'Laptop',
        mobileAlt: 'Mobile Alternate',
        camera: 'Camera',
        film: 'Film',
        music: 'Music',
        gamepad: 'Gamepad',
        basketballBall: 'Basketball Ball',
        footballBall: 'Football Ball',
        bicycle: 'Bicycle',
        car: 'Car',
        bus: 'Bus',
        plane: 'Plane',
        train: 'Train',
        ship: 'Ship',
        home: 'Home',
        building: 'Building',
        city: 'City',
        shoppingCart: 'Shopping Cart',
        moneyBillWave: 'Money Bill Wave',
        chartBar: 'Chart Bar',
        chartPie: 'Chart Pie',
        chartLine: 'Chart Line',
        users: 'Users',
        user: 'User',
        cog: 'Cog',
        bell: 'Bell',
        envelope: 'Envelope',
        lock: 'Lock',
        eye: 'Eye',
        eyeSlash: 'Eye Slash',
        upload: 'Upload',
        download: 'Download',
        trash: 'Trash',
        cameraRetro: 'Camera Retro',
        cloud: 'Cloud',
        cloudSun: 'Cloud Sun',
        cloudMoon: 'Cloud Moon',
        snowflake: 'Snowflake',
        smile: 'Smile',
        sadCry: 'Sad Cry',
        thumbsUp: 'Thumbs Up',
        thumbsDown: 'Thumbs Down',
        lightbulb: 'Lightbulb',
        rocket: 'Rocket',
        calendar: 'Calendar',
        clock: 'Clock',
        map: 'Map',
        star: 'Star',
        heart: 'Heart',
        comment: 'Comment',
        share: 'Share',
      },
      tags: {
        tools: 'Tools',
        construction: 'Construction',
        mechanic: 'Mechanic',
        safety: 'Safety',
        machines: 'Machines',
        vehicles: 'Vehicles',
        fire: 'Fire',
        emergency: 'Emergency',
        health: 'Health',
        medical: 'Medical',
        fitness: 'Fitness',
        pharmacy: 'Pharmacy',
        vaccine: 'Vaccine',
        doctor: 'Doctor',
        dental: 'Dental',
        education: 'Education',
        school: 'School',
        learning: 'Learning',
        technology: 'Technology',
        computer: 'Computer',
        work: 'Work',
        mobile: 'Mobile',
        communication: 'Communication',
        photography: 'Photography',
        media: 'Media',
        entertainment: 'Entertainment',
        gaming: 'Gaming',
        sports: 'Sports',
        basketball: 'Basketball',
        recreation: 'Recreation',
        transport: 'Transport',
        public: 'Public',
        travel: 'Travel',
        urban: 'Urban',
        town: 'Town',
        location: 'Location',
        shopping: 'Shopping',
        retail: 'Retail',
        purchase: 'Purchase',
        finance: 'Finance',
        money: 'Money',
        economy: 'Economy',
        business: 'Business',
        data: 'Data',
        analytics: 'Analytics',
        people: 'People',
        community: 'Community',
        social: 'Social',
        profile: 'Profile',
        settings: 'Settings',
        gear: 'Gear',
        notifications: 'Notifications',
        alert: 'Alert',
        messages: 'Messages',
        security: 'Security',
        protection: 'Protection',
        visibility: 'Visibility',
        see: 'See',
        hide: 'Hide',
        files: 'Files',
        delete: 'Delete',
        vintage: 'Vintage',
        weather: 'Weather',
        nature: 'Nature',
        sun: 'Sun',
        moon: 'Moon',
        winter: 'Winter',
        emotions: 'Emotions',
        happiness: 'Happiness',
        sadness: 'Sadness',
        approval: 'Approval',
        disapproval: 'Disapproval',
        ideas: 'Ideas',
        inspiration: 'Inspiration',
        space: 'Space',
        launch: 'Launch',
        time: 'Time',
        schedule: 'Schedule',
        maps: 'Maps',
        navigation: 'Navigation',
        rating: 'Rating',
        favorites: 'Favorites',
        comments: 'Comments',
        feedback: 'Feedback',
      },
    },
  },
  pages: {
    login: {
      labels: {
        username: 'Username',
        password: 'Password',
        totp: 'One-time code',
        autologin: 'Stay logged-in?',
        submit: {
          credentials: 'Login',
          totp: 'Confirm',
        },
      },
      links: {
        passwordReset: 'Password forgotten?',
        imprint: 'Imprint',
        privacy: 'Privacy',
      },
      totp: {
        description:
          'As an additional security measure, please enter the numeric code generated by your authenticator app.',
      },
      validation: {
        usernameRequired: 'Username is required.',
        passwordRequired: 'Password is required.',
        invalidCredentials: 'Username or password is wrong.',
        totpRequired: 'TOTP is required.',
        sessionStartFailed: 'There was an issue logging in. Please try again later.',
      },
    },
    project: {
      header: {
        projectAddress: 'Project Address',
        projectStatus: 'Project Status',
        billingType: 'Billing Type',
        responsibleProjectManager: 'Responsible Project Manager',
        startOfConstruction: 'Start of Construction',
        endOfConstruction: 'End of Construction',
        progress: 'Progress',
        //
        totalTime: 'Total Time',
        alreadyCompleted: 'Already Completed',
        unproductive: 'Unproductive',
        unproductiveTime: 'Unproductive Time',
        productiveTime: 'Productive Time',
        nonContractual: 'Non Contractual',
        nonContractualTime: 'Non Contractual Time',
        contractualTimeOriginalPlan: 'Contractual Time(Original Plan)',
        addendum: 'Addendum',
        deletedPositions: 'Deleted Positions',
        addedUnits: 'Added Units',
        reducedUnits: 'Reduced Units',
        differenceToOriginalCalculation: 'Difference to Original Calculation (Actual Plan)',
        currentOrderVolume: 'Current Order Volume',
        PAC: 'PAC',
        toBeDone: 'To be Done',
        differenceToActualPlan: 'Difference to Actual Plan',
        differenceToOriginalPlan: 'Difference to Original Plan',
        open: 'Open',
        //
        details: 'Details',
        PACTotal: 'Plan-Actual-Comparison (PAC)',
        last7Days: 'Last 7 Days',
        process: 'Process',
        //
        valuable: 'Valuable',
        nonBillable: 'Non Billable',
        nonValuable: 'Non Valuable',
        //
        travelTime: 'Travel Time',
        setupTime: 'Setup Time',
        otherUnproductiveTime: 'Other Unproductive Time',
        //
        totalOverview: 'Total Overview',
        times: 'Times',
        hours: 'Hours',
        factor: 'Factor',
        percentage: 'Proportion in %',
        originalOrderTime: 'Original order time',
        //
        h: 'h',
        //
        PACDetails: 'PAC Details',
      },
    },
    dashboard: {
      labels: {
        dailyPlanning: 'Daily Planning',
        timeRecording: 'Time Recording',
        dailyReports: 'Daily Reports',
        photoDocumentation: 'Photo Documentation',
        POBox: 'P.O. Box',
        tasks: 'Tasks',
        nonContractualWork: 'Non Contractual Work',
        quickLinks: 'Quick Links',
        vacations: 'Vacations',
      },
      vacations: {
        currentYearClaim: 'Claim',
        lastYearClaim: 'Last year claim',
        totalClaim: 'Total claim',
        taken: 'Taken',
        planned: 'Planned',
        requested: 'Requested',
        rest: 'Rest',
      },
    },
    linkModule: {
      title: 'Link Module',
    },
  },
  modals: {
    linkModule: {
      addTitle: 'Add Link',
      editTitle: 'Edit Link',
    },
    iconPicker: {
      title: 'Pick an icon',
    },
    delete: {
      title: 'Are you sure?',
      body: 'Are you sure you want to delete this item? This action is irreversible!',
    },
  },
  errors: {
    required: 'This field is required',
    invalidURL: 'Invalid URL format',
  },
};

export default en;
