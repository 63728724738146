import { lazy } from 'react';
import { ModalItem } from '~/ui/modal/modal-context';
import { ModalNames } from './modal-names';

// Lazy load the modals
const LinkModuleModal = lazy(() => import('~/modals/link-module/link-module-modal'));

export const modals: ModalItem[] = [
  {
    name: ModalNames.LINK_MODULE_MODAL,
    component: LinkModuleModal,
  },
];
