import { configureStore } from '@reduxjs/toolkit';

import dailyPlanningSlice from './slices/dashboard/dailyPlanningSlice';
import dashboardSlice from './slices/dashboard/dashboardSlice';
import projectSlice from './slices/projectSlice';
import userSlice from './slices/userSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    project: projectSlice,
    dashboard: dashboardSlice,
    dailyPlanning: dailyPlanningSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
