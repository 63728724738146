import { AxiosError } from 'axios';

export default class GalaworkApiError extends Error {
  previousError: AxiosError;

  constructor(message: string, previousError: AxiosError) {
    super(message);

    this.previousError = previousError;
  }

  getPrevious() {
    return this.previousError;
  }
}
