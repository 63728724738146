import i18next from 'i18next';

export enum SupportedLocale {
  English = 'en',
  German = 'de',
}

export type Localization = 'en' | 'de';

export const SEPARATORS = {
  [SupportedLocale.English]: {
    thousand: ',',
    decimal: '.',
  },
  [SupportedLocale.German]: {
    thousand: '.',
    decimal: ',',
  },
};

export const getLocale = () => i18next.language;
