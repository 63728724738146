import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '~/enums/RequestStatus';
import ApiService from '~/services/ApiService';

export type Project = {
  id: number;
  external_id: number;
  number: string;
  status: string;
  status_description: string;
  title: string;
  address: string | null;
  latitude: string;
  longitude: string;
  radius: number;
  manager_id: number;
  client: {
    name: string;
    address: {
      street: string;
      zip: string;
      city: string;
    };
  };
  contacts: {
    note: string;
    name: string;
    email: string;
    tel: string;
  }[];
  manager: {
    id: number;
    firstname: string;
    lastname: string;
    username: string;
  } | null;
  manager_id_external: string | null;
  foreman_id_external: string | null;
  factor: number;
  calculated_time_with_factor: number;
  calculated_time_with_factor_seconds: number;
  calculated_time: number;
  calculated_time_seconds: number;
  original_calculated_time: number;
  original_calculated_time_seconds: number;
  original_calculated_time_with_factor_seconds: number;
  worked_time: number | null;
  worked_time_seconds: number | null;
  start_date: string;
  additional_work_time: number;
  additional_work_time_seconds: number;
  remaining_time: number;
  remaining_time_seconds: number;
  completion_date: string;
  completion_forecast_date: string;
  order_value: number;
  billing_basis: string;
  is_trashed: false;
  created_at: string;
  updated_at: string | null;
  non_productive_time: {
    time_total: number;
    time_total_seconds: number;
    travel_time: number;
    travel_time_seconds: number;
    setup_time: number;
    setup_time_seconds: number;
    other_time: number;
    other_time_seconds: number;
  };
  non_contractual_time: {
    time_total: number;
    time_total_seconds: number;
    non_billable: number;
    non_billable_seconds: number;
    valuable_time: number;
    valuable_time_seconds: number;
    non_valuable_time: number;
    non_valuable_time_seconds: number;
  };
  pac_time_limit_value: number;
  pac_time_total: number;
  pac_time_total_seconds: number;
  pac_time_preview_total: number;
  pac_time_preview_total_seconds: number;
  pac_time_preview: {
    [key: string]: number | null;
  };
};

export type ProjectPac = {
  id: number;
  title: string;
  number: string;
  pac_time_limit_value: number;
  pac_time_total: number;
  pac_time_total_seconds: number;
  pac_time_preview_total: number;
  pac_time_preview_total_seconds: number;
  pac_time_preview: {
    [key: string]: number | null;
  };
};

interface ProjectState {
  project: Project | null;
  status: RequestStatus;
  projectPac: ProjectPac | null;
  PacStatus: RequestStatus;
  error: string | null;
}

const initialState: ProjectState = {
  project: null,
  status: RequestStatus.IDLE,
  projectPac: null,
  PacStatus: RequestStatus.IDLE,
  error: null,
};

interface GetSingleProjectPacParams {
  projectId: string;
  startDate: string;
  endDate: string;
}

export const getSingleProject = createAsyncThunk('projects/getSingleProject', async (projectId: string) => {
  const response = await ApiService.get<Project>(`/api/projects/${projectId}/stats`);
  return response.data;
});

export const getSingleProjectPac = createAsyncThunk(
  'projects/getSingleProjectPac',
  async ({ projectId, startDate, endDate }: GetSingleProjectPacParams) => {
    const response = await ApiService.get<ProjectPac>(`/api/projects/${projectId}/pac-time`, {
      params: {
        date_from: startDate,
        date_to: endDate,
      },
    });

    return response.data;
  }
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSingleProject.pending, state => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(getSingleProject.fulfilled, (state, action: PayloadAction<Project>) => {
        state.status = RequestStatus.SUCCEEDED;
        state.project = action.payload;
      })
      .addCase(getSingleProject.rejected, (state, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message || 'Something went wrong';
      })
      //
      .addCase(getSingleProjectPac.pending, state => {
        state.PacStatus = RequestStatus.LOADING;
      })
      .addCase(getSingleProjectPac.fulfilled, (state, action: PayloadAction<ProjectPac>) => {
        state.PacStatus = RequestStatus.SUCCEEDED;
        state.projectPac = action.payload;
      })
      .addCase(getSingleProjectPac.rejected, (state, action) => {
        state.PacStatus = RequestStatus.FAILED;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default projectSlice.reducer;
